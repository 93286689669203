import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, TextField, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  fieldWidth: {
    width: '275px',
    [theme.breakpoints.up('sm')]: {
      width: '325px',
    },
  },
}));

class RewardsComponentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendingAddress: '',
      coldStakingBalance: 'Loading...',
      generationStatus: 0,
    };

    this.RenderRewardsComponentPage = this.RenderRewardsComponentPage.bind(this);
  }

  RenderRewardsComponentPage() {
    const classes = useStyles();

    const { generationStatus, sendingAddress, coldStakingBalance } = this.state;

    const getColdStakingBalance = async () => {
      let request;
      try {
        request = await axios.get(`https://tzcfarm.com:31452/getBalance?address=${sendingAddress}`);
      } catch (e) {
        console.error(e);
      }
      const coldStakingBalance = request.data.balance;
      this.setState({ coldStakingBalance });
    }

    return (
      <Container>
        <TextField
          required id="address input"
          label="Your Cold Staking Address"
          placeholder="Your Cold Staking Address..."
          variant="filled"
          color="secondary"
          value={sendingAddress}
          onChange={(event) => this.setState({ sendingAddress: event.target.value })}
          className={classes.fieldWidth}
          InputLabelProps={{
            style: {
              opacity: '0.8',
              color: 'white'
            },
          }}
          inputProps={{
            style: {
              opacity: '1',
              color: 'white'
            },
          }}
          style={{ opacity: '1' }}
        />
        {generationStatus === 0 && (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            onClick={() => {
              getColdStakingBalance();
              this.setState({ generationStatus: 1 })}
            }
            className={classes.fieldWidth}
            disabled={sendingAddress.length !== 61}
            style={{ letterSpacing: '2px', fontWeight: 'bold', display: 'block', margin: '10px auto' }}>
            VIEW BALANCE
          </Button>
        )}
        {generationStatus === 1 && (
          <>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
              Your Cold Staking Balance
            </Typography>
            <TextField
              id="staking address"
              label="Yor Cold Staking Balance"
              value={coldStakingBalance}
              variant="filled"
              color="secondary"
              disabled
              className={classes.fieldWidth}
              InputLabelProps={{
                style: {
                  opacity: '1',
                  color: 'white'
                },
              }}
              inputProps={{
                style: {
                  opacity: '1',
                  color: 'white'
                },
              }}
              style={{ opacity: '1' }}
            />
          </>
        )}
      </Container>
    )
  }

  render() {
    return (
      <this.RenderRewardsComponentPage />
    );
  }
}

export default RewardsComponentPage;