import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';

import customTheme from './theme/muiTheme';
import MainPage from './pages/Main';
import NavBar from './pages/NavBar';

import './App.css';

function App() {
  return (
    <MuiThemeProvider theme={customTheme}>
      <Router>
        <CssBaseline />
        <Route
          exact
          path="/"
          render={props => (
            <div>
              <NavBar />
              <MainPage
                {...props}
              />
            </div>)}
        />
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
