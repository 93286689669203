import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';

import TzcLogo from '../res/TZC-100x.png';
import { AccountBalance, AccountBalanceWallet, Lock } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {

  },
  logo: {
    height: 'auto',
    width: '32px',
    marginRight: '8px',
  },
  statLogo: {
    fontSize: '1rem',
  }
}));

class NavBarPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: {}
    };

    this.RenderNavBarPage = this.RenderNavBarPage.bind(this);
  }

  async componentDidMount() {
    let request;
    try {
      request = await axios.get(`https://tzcfarm.com:31452/getStats`);
    } catch (e) {
      console.error(e);
    }
    this.setState({ stats: request.data });
  }

  RenderNavBarPage() {
    const classes = useStyles();
    const { stats } = this.state;

    return (
      <AppBar position="static">
        <Toolbar variant="dense">
          <img src={TzcLogo} className={classes.logo} />
          <Typography variant="h6" color="inherit">
            TZCFarm
          </Typography>
          <Hidden smDown>
            <Typography variant="h6" color="inherit">
    &nbsp;| <Lock size="small" className={classes.statLogo}/> <b>{stats.coldStakingBalance}</b> TZC Locked for Staking
            </Typography>
            <Typography variant="h6" color="inherit">
              &nbsp;| <AccountBalanceWallet size="small" className={classes.statLogo} /> <b>{stats.coldStakingAccounts}</b> Cold Staking Addressed Created
            </Typography>
          </Hidden>
          <Hidden smUp>
            <Typography variant="h6" color="inherit">
            &nbsp;| <Lock size="small" className={classes.statLogo}/> <b>{stats.coldStakingBalance}</b>
            </Typography>
            <Typography variant="h6" color="inherit">
              &nbsp;| <AccountBalanceWallet size="small" className={classes.statLogo} /> <b>{stats.coldStakingAccounts}</b>
            </Typography>
          </Hidden>
        </Toolbar>
      </AppBar>
    )
  }

  render() {
    return (
      <this.RenderNavBarPage />
    );
  }
}

export default NavBarPage;