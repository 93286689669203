import { createMuiTheme } from '@material-ui/core/styles';
require('typeface-titillium-web');

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#16174f', 
      main: '#16174f', 
      dark: '#16174f', 
      contrastText: '#ffffff', 
    },
    secondary: {
        light: '#ffffff',
        main: '#ffffff',
        dark: '#ffffff',
        contrastText: '#4328b7',
    }
  },
  typography: {
      fontFamily: 'Titillium Web, Helvetica, Arial, Lucida, sans-serif',
  },
});

export default theme;