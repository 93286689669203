import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Button, IconButton } from '@material-ui/core';

import StakeComponent from './StakeComponent';
import RewardsComponent from './RewardsComponent';

import TZCLogo from '../res/Trezar_White.png';
import { KeyboardReturn } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#16174f',
    minHeight: '600px',
    minWidth: '375px',
    width: `min(${window.innerWidth}px, 475px)`,
    borderRadius: '8px',
    paddingTop: '16px',
    marginTop: '16px',
  },
  logo: {
    height: 'auto',
    width: '125px',
  }
}));

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      farmingStatus: 0,
      stats: {},
    };

    this.RenderMainPage = this.RenderMainPage.bind(this);
  }

  RenderMainPage() {
    const classes = useStyles();
    const { farmingStatus } = this.state;
    console.log(window.innerWidth);

    return (
      <Container className={classes.root}>
        <Container style={{ marginTop: '32px' }}>
        <img src={TZCLogo} className={classes.logo} />
          <Typography variant="h3" color="inherit" style={{ marginBottom: '8px' }}>
              TZCFarm
            </Typography>
            <Typography variant="h5" color="inherit">
              Start farming TrezarCoin today
            </Typography>
            <Typography variant="body2" color="inherit" style={{ maxWidth: '325px', margin: '15px auto', textAlign: 'justify' }}>
              TZC Farm offers the ability to stake your Coins safely via the ColdStaking function of Trezarcoin. ColdStaking opens up the opportunity to delegate your Coins to a stakingpool which directly pays the rewards to the ColdStakingAddress which is created uniquely for you.
            </Typography>

          {farmingStatus === 0 && (
            <>
              <Button color="secondary" variant="contained" size="medium" style={{ letterSpacing: '2px', fontWeight: 'bold', margin: '5px' }} onClick={() => this.setState({ farmingStatus: 1 })}>
                START FARMING
              </Button>
              <Button color="secondary" variant="contained" size="medium" style={{ letterSpacing: '2px', fontWeight: 'bold', margin: '5px' }} onClick={() => this.setState({ farmingStatus: 2 })}>
                VIEW BALANCE
              </Button>
            </>
          )}
          {(farmingStatus === 1 || farmingStatus === 2) && (
            <IconButton 
              color="inherit" 
              style={{ padding: 0, marginTop: '0px', marginBottom: '6px' }} 
              size="small"
              onClick={() => this.setState({ farmingStatus: 0 })}
            >
              <KeyboardReturn />
              &nbsp;Back
            </IconButton>
          )}
          {farmingStatus === 1 && (
            <StakeComponent />
          )}
          {farmingStatus === 2 && (
            <RewardsComponent />
          )}
        </Container>
      </Container>
    )
  }

  render() {
    return (
      <this.RenderMainPage />
    );
  }
}

export default MainPage;