import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, TextField, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  fieldWidth: {
    width: '275px',
    [theme.breakpoints.up('sm')]: {
      width: '325px',
    },
  },
}));

class StakeComponentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendingAddress: '',
      stakingAddress: 'Loading...',
      generationStatus: 0,
    };

    this.RenderStakeComponentPage = this.RenderStakeComponentPage.bind(this);
  }

  RenderStakeComponentPage() {
    const classes = useStyles();

    const { generationStatus, sendingAddress, stakingAddress } = this.state;

    const getColdStakingAddress = async () => {
      let request;
      try {
        request = await axios.post('https://tzcfarm.com:31452/createColdStakingAddress',
          {
            address: sendingAddress,
          });
      } catch (e) {
        console.error(e);
      }
      const coldStakingAddress = request.data.coldStakingAddress.result;
      this.setState({ stakingAddress: coldStakingAddress });
    }

    return (
      <Container>
        <TextField
          required id="address input"
          label="Your Spending Address"
          placeholder="Input your Spending Address..."
          variant="filled"
          color="secondary"
          value={sendingAddress}
          onChange={(event) => this.setState({ sendingAddress: event.target.value })}
          className={classes.fieldWidth}
          InputLabelProps={{
            style: {
              opacity: '0.8',
              color: 'white'
            },
          }}
          inputProps={{
            style: {
              opacity: '1',
              color: 'white'
            },
          }}
          style={{ opacity: '1' }}
        />
        {generationStatus === 0 && (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            onClick={() => {
              getColdStakingAddress();
              this.setState({ generationStatus: 1 })}
            }
            className={classes.fieldWidth}
            disabled={sendingAddress.length !== 34}
            style={{ letterSpacing: '2px', fontWeight: 'bold', display: 'block', margin: '10px auto' }}>
            GENERATE STAKING ADDRESS
          </Button>
        )}
        {generationStatus === 1 && (
          <>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
              Copy the Staking Address and send your Coins to it
            </Typography>
            <TextField
              id="staking address"
              label="Your Staking Address"
              value={stakingAddress}
              variant="filled"
              color="secondary"
              disabled
              className={classes.fieldWidth}
              InputLabelProps={{
                style: {
                  opacity: '1',
                  color: 'white'
                },
              }}
              inputProps={{
                style: {
                  opacity: '1',
                  color: 'white'
                },
              }}
              style={{ opacity: '1' }}
            />
          </>
        )}
      </Container>
    )
  }

  render() {
    return (
      <this.RenderStakeComponentPage />
    );
  }
}

export default StakeComponentPage;